<template>
	<div class="list-accordions">
		<div
			class="header margin-bottom-l"
			v-if="heading || ingress"
		>
			<TnHeading
				v-if="heading"
				type="h2"
				v-editable-string
				>{{ heading }}
			</TnHeading>
			<TnParagraph
				v-if="ingress"
				v-html="ingress"
			/>
		</div>

		<Tabs
			v-if="tabs"
			:tabs="tabs"
			@TabSelection="selectTab"
			:selected="tabIndex"
			exemptFromHash
			class="padding-bottom-m"
		/>
		<AccordionsTab
			v-for="(tab, tabIndex) in accordionTabs"
			v-if="activeTab(tabIndex)"
			:accordionTabList="tab"
			:key="`accordionTab-${tabIndex}`"
		/>
		<div
			v-if="faqMarkupEnabled"
			v-html="faqDataJsonld"
		></div>
	</div>
</template>

<script>
export default defineNuxtComponent({
	props: {
		component: {
			type: Object,
		},
	},
	data() {
		return {
			heading: this.component.content.accordionTopHeading,
			ingress: this.component.content.accordionTopIngress,
			tabIndex: 0,
		};
	},

	computed: {
		accordionTabs() {
			let tabs = this.component.content.accordionTabList;

			if (!tabs && this.component.content.accordionList) {
				tabs = [
					{
						accordionList: this.component.content.accordionList,
						accordionMoreLinkText: this.component.content.accordionMoreLinkText,
						accordionLessLinkText: this.component.content.accordionLessLinkText,
					},
				];
			}

			return tabs;
		},
		tabs() {
			if (this.accordionTabs.length > 1) {
				const tabs = [];
				this.accordionTabs.forEach((tab) => {
					if (tab.tabText) tabs.push(tab.tabText);
				});
				return tabs;
			}
		},
		faqMarkupEnabled() {
			return this.component.content?.enableFAQmarkup === true;
		},
		faqDataJsonld() {
			if (this.faqMarkupEnabled) {
				const structuredData = {
					"@context": "https://schema.org",
					"@type": "FAQPage",
					mainEntity: this.accordionTabs?.flatMap((list) =>
						list?.accordionList?.map((tab) => ({
							"@type": "Question",
							name: tab?.accordionHeading,
							acceptedAnswer: {
								"@type": "Answer",
								text: tab?.accordionContent,
							},
						})),
					),
				};
			}
			return "";
		},
	},

	methods: {
		selectTab(event) {
			this.tabIndex = event.index;
		},
		activeTab(index) {
			return index === this.tabIndex;
		},
	},
});
</script>

<style lang="scss" scoped>
.list-accordions {
	.header .paragraph {
		margin-top: $spacing-m;
	}
}
</style>
