<template>
	<div class="accordion">
		<div
			class="trigger padding-vertical-m padding-right-3xl"
			tabindex="0"
			@click="triggerAccordion"
			@keyup.enter="triggerAccordion"
		>
			<div
				:class="item.accordionIcon ? 'image' : ''"
				class="icon margin-horizontal-l"
			>
				<img
					v-if="item.accordionIcon"
					:alt="item.accordionIcon.alt"
					:src="item.accordionIcon.src"
				/>
				<span v-else>{{ item.accordionTextIcon }}</span>
			</div>
			<TnHeading
				responsive
				size="xs"
				type="h4"
			>
				<span
					v-if="item.accordionHeading"
					v-editable-string
					:class="{ strong: boldHeading }"
					>{{ item.accordionHeading }}</span
				>
				<span
					v-if="item.accordionSubheading"
					v-editable-string
					>{{ item.accordionSubheading }}</span
				>
			</TnHeading>
			<TnIcon
				:name="accordionOpen ? 'chevron-up' : 'chevron-down'"
				class="chevron"
			></TnIcon>
		</div>

		<TnTransitionHeight :show-if="accordionOpen">
			<div class="accordion-content padding-vertical-l padding-horizontal-m">
				<RichText
					:text="item.accordionContent"
					class="content"
				/>
				<RichText
					v-if="item.accordionExtraContent && stripHTML(item.accordionExtraContent)"
					:text="item.accordionExtraContent"
					class="extra-content margin-left-s"
				/>
			</div>
		</TnTransitionHeight>
	</div>
</template>

<script>
export default defineNuxtComponent({
	props: {
		item: {
			type: Object,
		},
		boldHeading: {
			type: Boolean,
		},
	},

	data() {
		return {
			accordionOpen: false,
		};
	},

	methods: {
		triggerAccordion() {
			this.accordionOpen = !this.accordionOpen;
		},
		stripHTML(html) {
			const regex = /(<([^>]+)>)/gi;
			return html.replace(regex, "");
		},
	},
});
</script>

<style lang="scss" scoped>
.has-bg .accordion .trigger {
	background-color: #fff;
	.color-theme--white {
		background: $color-neutrals-50-tint;
	}

	.icon {
		background-color: $color-neutrals-50-tint;
	}
}

.accordion {
	font-family: $font-family-telenor-ui;
	text-align: left;
	margin-top: $spacing-m;

	.chevron {
		margin-left: auto;
		color: $color-cta-default;
	}

	.trigger {
		display: flex;
		align-items: center;
		background: $color-neutrals-50-tint;
		background-size: 12px;
		border-radius: 7px;
		cursor: pointer;
		user-select: none;

		.strong {
			font-weight: bold;
		}

		.icon {
			width: 40px;
			height: 40px;
			display: grid;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			background: $color-background-white;
			border-radius: 50%;
			color: $color-information-500-core;

			@include font-title-bold-xs;

			&.image {
				background: none;

				img {
					width: 40px;
					height: 40px;
					object-fit: contain;
				}
			}
		}

		@media (max-width: $size-screen-xs) {
			background-position: right $spacing-m center;
		}
	}

	.accordion-content {
		display: flex;
		justify-content: space-between;
		max-width: 100%;

		.content {
			@include font-text-l;
		}

		.extra-content {
			background: $color-neutrals-50-tint;
			border-radius: 7px;
			width: 33%;
			padding: $spacing-xl $spacing-l;
		}

		@media (max-width: $size-screen-xs) {
			flex-direction: column;

			.extra-content {
				width: 100%;
			}
		}
	}
}

.bg-dark .accordion .accordion-content {
	color: $color-neutrals-white;
}
</style>
