<template>
	<div class="tab-content">
		<AccordionsBlock
			v-for="(item, index) in accordions"
			:key="`accordion-${index}`"
			:boldHeading="boldHeading"
			:item="item"
		/>

		<TnTransitionHeight :showIf="showAll && moreAccordions">
			<AccordionsBlock
				v-for="(item, moreIndex) in moreAccordions"
				:key="`moreAccordion-${moreIndex}`"
				:boldHeading="boldHeading"
				:item="item"
			/>
		</TnTransitionHeight>

		<a
			v-if="moreAccordions"
			class="toggle-more padding-top-xl"
			tabindex="0"
			@click="toggleShowAll"
			@keyup.enter="toggleShowAll"
		>
			<template v-if="!showAll">{{ moreText }}</template>
			<template v-else>{{ lessText }}</template>
		</a>
	</div>
</template>

<script>
export default defineNuxtComponent({
	props: {
		accordionTabList: {
			type: Object,
		},
	},

	data() {
		return {
			showAll: false,
		};
	},

	computed: {
		moreText() {
			return this.accordionTabList.accordionMoreLinkText;
		},
		lessText() {
			return this.accordionTabList.accordionLessLinkText;
		},
		accordions() {
			if (this.accordionTabList.accordionMoreLinkText) {
				return this.accordionTabList.accordionList.slice(0, 5);
			} else {
				return this.accordionTabList.accordionList;
			}
		},
		moreAccordions() {
			if (this.accordionTabList.accordionMoreLinkText && this.accordionTabList.accordionList.length > 5)
				return this.accordionTabList.accordionList.slice(5);
			return false;
		},
		boldHeading() {
			const hasSubheading = (accordion) => accordion.accordionSubheading;
			return this.accordionTabList.accordionList.some(hasSubheading);
		},
	},

	methods: {
		toggleShowAll() {
			this.showAll = !this.showAll;
		},
	},
});
</script>

<style lang="scss" scoped>
.tab-content {
	.toggle-more {
		display: inline-block;

		@include font-text-m;
	}

	a {
		color: $color-cta-default;
		border-bottom: 1px dotted;
		cursor: pointer;
		text-decoration: none;

		&:hover {
			border-bottom-style: solid;
		}
	}
}
</style>
